
import React from 'react';
import "./rps.css";
import RpsPageDescription from "./rpsPageDesc/rps_page_desc";
import RpsPageInfomation from "./rpsPageInfo/rpsPageInfo";

function RPO() {
  return (
    <div className="rpo-container">
      <div className="rpo-header-wrapper">
        <img
          className="rpo-background-image"
          src="./images/rps/rps_page.jpg"
          alt="recruitment process outsourcing"
        />
        <div className="rpo-overlay-text">
          <div className="rpo-text-container">
            <span className="rpo-text rpo-text-primary">RECRUITMENT</span>
            <span className="rpo-text rpo-text-primary">PROCESS</span>
            <span className="rpo-text rpo-text-primary">OUTSOURCING</span>
            <span className="rpo-text rpo-text-solution">SOLUTIONS</span>
          </div>
        </div>
      </div>
      <RpsPageDescription />
      <RpsPageInfomation />
    </div>
  );
}

export default RPO;
import "./design_services.css";

function DesignServices() {
  return (
    <>
      <div className="row g-0">
        <div className="col-12 d-flex justify-content-center  align-items-center">
          <div className="d-flex flex-column text-center position-relative designSerLeft">
            <div className="desSerYellowDiv">
              <span className="desSerYellowTxt font-face-Corki position-relative">
                Design
              </span>
            </div>
            <div className="desSerBlackDiv">
              <span className="desSerBlackTxt font-face-Corki position-relative">
                Services
              </span>
            </div>
          </div>

          <img
            className="designSerImg"
            src="./images/home/design_service.jpg"
            alt="Design Servies"
          ></img>
          <div className="d-flex flex-column text-left font-face-OpenSans  position-relative designSerRight">
            <span className="designSerTxt1">
              We offer premium Design Services, delivering high-quality
              solutions in PCB Design, Product Prototyping, and Embedded
              Systems.
            </span>
            <span className="designSerTxt2 mt-4">
              <b>
                Our experienced design team leverages the latest tools and
                technology to bring your ideas to life, from concept to
                implementation, ensuring your product development cycle is fast,
                efficient, and aligned with market demands.
              </b>
            </span>
            <span className="designSerTxt2 mt-4">
              <b>
                We offer customized recruitment process solutions, to scale your
                business. Our robust RPO solutions effectively attract talent
                while minimizing hiring time (TAT) and costs.
              </b>
            </span>
            <span className="designSerTxt2 mt-4">
              <b>
                QP Proto is our prototype build services that streamlines
                mechanical, electrical, and electronic product development,
                enabling rapid prototyping and innovation. We collaborate
                closely with clients to transform concepts into functional
                prototypes, ensuring precision and quality at every stage. Our
                excellent infrastructure supports building any kind of prototype
                with quick turnaround time.
              </b>
            </span>
          </div>
        </div>
      </div>
    </>
  );
}

export default DesignServices;



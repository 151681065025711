import LetUsConnect from "./connect/connect";
import DesignServices from "./design_services/design_services";
import Empower from "./empower/empower";
import "./homePageImage.css";
import OurExpertise from "./our_expertise/our_expertise";
import QuntamPlus from "./quntam_plus/quntam_plus";

function HeaderPageImage() {
  return (
    <>
       <div className="row g-0">
        <div className="col-12">
          <div className="imageBg text-center d-flex flex-column align-items-center position-relative">
            <img
              src="./images/home/homePage1.png"
              alt="heading"
              className="img-fluid homeImage"
            />
            <div className="imgHedBgDiv font-face-BebasNeue d-flex flex-wrap align-items-center justify-content-center px-3 text-center">
              <div className="imgHeadBgTxtSpan1">Unlock</div>
              <div className="imgHeadBgTxtSpan2">'TALENTS',</div>
              <div className="imgHeadBgTxtSpan3">Accelerate</div>
              <div className="imgHeadBgTxtSpan4">'INNOVATION'</div>
            </div>
            <div className="imgHedBgDiv2 d-flex align-items-center px-3 text-center">
              <div className="imgHedBgDiv2TxtSpan mx-auto font-face-OpenSans">
                At Quantum Pulse Technologies, we specialize in Design Services to empower businesses of all sizes
                and delivering world-class Recruitment Process Outsourcing (RPO) solutions. Whether you’re looking
                to streamline your hiring process or require cutting-edge design expertise, we combine industry-leading
                technology with deep sector knowledge to help you achieve your organizational goals.
              </div>
            </div>
          </div>
        </div>
      </div> 
      <OurExpertise />
      <QuntamPlus />
      <DesignServices />
      <Empower />
      <LetUsConnect />
    </>
  );
}

export default HeaderPageImage;
import "./support_service.css";

// function SupportServices() {
//   return (
//     <>
//       <div className="row g-0 my-0 suptSecBg pt-5 pb-5">
//         <div className="col-6 d-flex justify-content-end p-3">
//           <div className="supSerMainDiv">
//             {/* <div className="supSerImgBg"></div> */}
//             <img
//               src="./images/design_services/DS_Img_1.jpg"
//               alt="Design Service"
//             />
//             <div className="font-face-Thasadith mt-3 supSerHedTxt">
//               PCB DESIGN SERVICES
//             </div>
//             <p className="font-face-OpenSans mt-3 supSerDesTxt">
//               Our PCB Design Services offer full-cycle development, including
//               schematic design, PCB layout, and prototyping. We handle
//               high-speed PCB design, multi-layer board creation, and power
//               system optimization to ensure superior functionality...
//             </p>
//           </div>
//         </div>
//         <div className="col-6 d-flex p-3">
//           <div className="supSerMainDiv">
//             {/* <div className="supSerImgBg"></div> */}
//             <img
//               src="./images/design_services/DS_Img_2.jpg"
//               alt="Design Service"
//             />
//             <div className="font-face-Thasadith mt-3 supSerHedTxt">
//               EMBEDDED SYSTEMS DEVELOPMENT
//             </div>
//             <p className="font-face-OpenSans mt-3 supSerDesTxt">
//               We offer specialized Embedded Systems Development for diverse
//               applications. Our team excels in microcontroller programming,
//               firmware development, and embedded software solutions. Whether
//               your focus is on IoT devices or real-time systems...
//             </p>
//           </div>
//         </div>
//         <div className="col-6 d-flex justify-content-end p-3">
//           <div className="supSerMainDiv">
//             {/* <div className="supSerImgBg"></div> */}
//             <img
//               src="./images/design_services/DS_Img_3.jpg"
//               alt="Design Service"
//             />
//             <div className="font-face-Thasadith mt-3 supSerHedTxt">
//               PRODUCT PROTOTYPING & DEVELOPMENT
//             </div>
//             <p className="font-face-OpenSans mt-3 supSerDesTxt">
//               We provide rapid prototyping services that allow you to test and
//               refine your ideas before moving to full-scale production. Using 3D
//               modeling, mechanical simulation, and functional testing...
//             </p>
//           </div>
//         </div>
//         <div className="col-6 d-flex p-3">
//           <div className="supSerMainDiv">
//             {/* <div className="supSerImgBg"></div> */}
//             <img
//               src="./images/design_services/DS_Img_4.jpg"
//               alt="Design Service"
//             />
//             <div className="font-face-Thasadith mt-3 supSerHedTxt">
//               IOT SOLUTIONS
//             </div>
//             <p className="font-face-OpenSans mt-3 supSerDesTxt">
//               Our expertise in the Internet of Things (IoT) allows businesses to build connected systems that improve efficiency and data-driven decision-making. We design and develop end-to-end IoT solutions...
//             </p>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }
// export default SupportServices;

// function SupportServices() {
//   return (
//     <>
//       <div className="row g-0 my-0 suptSecBg pt-5 pb-5">
//         <div className="col-6 d-flex justify-content-end p-3">
//           <div className="supSerMainDiv">
//             <img
//               src="./images/design_services/DS_Img_1.jpg"
//               alt="Design Service"
//             />
//             <div className="font-face-Thasadith mt-3 supSerHedTxt">
//               PCB DESIGN SERVICES
//             </div>
//             <p className="font-face-OpenSans mt-3 supSerDesTxt">
//               Our PCB Design Services offer full-cycle development, including
//               schematic design, PCB layout, and prototyping. We handle
//               high-speed PCB design, multi-layer board creation, and power
//               system optimization to ensure superior functionality...
//             </p>
//           </div>
//         </div>
//         <div className="col-6 d-flex p-3">
//           <div className="supSerMainDiv">
//             <img
//               src="./images/design_services/DS_Img_2.jpg"
//               alt="Design Service"
//             />
//             <div className="font-face-Thasadith mt-3 supSerHedTxt">
//               EMBEDDED SYSTEMS DEVELOPMENT
//             </div>
//             <p className="font-face-OpenSans mt-3 supSerDesTxt">
//               We offer specialized Embedded Systems Development for diverse
//               applications. Our team excels in microcontroller programming,
//               firmware development, and embedded software solutions. Whether
//               your focus is on IoT devices or real-time systems...
//             </p>
//           </div>
//         </div>
//         <div className="col-6 d-flex justify-content-end p-3">
//           <div className="supSerMainDiv">
//             <img
//               src="./images/design_services/DS_Img_3.jpg"
//               alt="Design Service"
//             />
//             <div className="font-face-Thasadith mt-3 supSerHedTxt">
//               PRODUCT PROTOTYPING & DEVELOPMENT
//             </div>
//             <p className="font-face-OpenSans mt-3 supSerDesTxt">
//               We provide rapid prototyping services that allow you to test and
//               refine your ideas before moving to full-scale production. Using 3D
//               modeling, mechanical simulation, and functional testing...
//             </p>
//           </div>
//         </div>
//         <div className="col-6 d-flex p-3">
//           <div className="supSerMainDiv">
//             <img
//               src="./images/design_services/DS_Img_4.jpg"
//               alt="Design Service"
//             />
//             <div className="font-face-Thasadith mt-3 supSerHedTxt">
//               IOT SOLUTIONS
//             </div>
//             <p className="font-face-OpenSans mt-3 supSerDesTxt">
//               Our expertise in the Internet of Things (IoT) allows businesses to build connected systems that improve efficiency and data-driven decision-making. We design and develop end-to-end IoT solutions...
//             </p>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }
// export default SupportServices;

import React from 'react';


function SupportServices() {
  const services = [
    {
      image: "./images/design_services/DS_Img_1.jpg",
      title: "PCB DESIGN SERVICES",
      description: "Our PCB Design Services offer full-cycle development, including schematic design, PCB layout, and prototyping. We handle high-speed PCB design, multi-layer board creation, and power system optimization to ensure superior functionality..."
    },
    {
      image: "./images/design_services/DS_Img_2.jpg",
      title: "EMBEDDED SYSTEMS DEVELOPMENT",
      description: "We offer specialized Embedded Systems Development for diverse applications. Our team excels in microcontroller programming, firmware development, and embedded software solutions. Whether your focus is on IoT devices or real-time systems..."
    },
    {
      image: "./images/design_services/DS_Img_3.jpg",
      title: "PRODUCT PROTOTYPING & DEVELOPMENT",
      description: "We provide rapid prototyping services that allow you to test and refine your ideas before moving to full-scale production. Using 3D modeling, mechanical simulation, and functional testing..."
    },
    {
      image: "./images/design_services/DS_Img_4.jpg",
      title: "IOT SOLUTIONS",
      description: "Our expertise in the Internet of Things (IoT) allows businesses to build connected systems that improve efficiency and data-driven decision-making. We design and develop end-to-end IoT solutions..."
    }
  ];

  return (
    <div className="support-services-container">
      <div className="services-grid">
        {services.map((service, index) => (
          <div key={index} className="service-card">
            <div className="service-content">
              <img 
                src={service.image} 
                alt={`${service.title} service`} 
                className="service-image"
              />
              <div className="service-title">{service.title}</div>
              <p className="service-description">{service.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default SupportServices;

import { Outlet, Link } from "react-router-dom";
import "./header.css";
import { useState } from "react";

function Header() {
  const [searchText, setSearchText] = useState("");

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };

  const handleSearch = () => {
    if (searchText.trim()) {
      console.log(`Searching for: ${searchText}`);
      // Implement your search logic here (e.g., navigate to search results or filter content).
    } else {
      console.log("Search text is empty");
    }
  };

  return (
    <div className="row g-0 justify-content-left minHeight">
      <div className="col-2"></div>
      <div className="col-4 d-flex align-items-center ps-3">
        <img
          className="logoImg"
          src="./images/logo/quantum_pulse.png"
          alt="icon"
        />
        <div className="d-flex flex-column hedingMinHeight">
          <div className="d-flex flex-row font-face-BebasNeue position-relative companyNameTop">
            <div className="logHeadingTxt1">QUANTUM</div>
            <div className="logHeadingTxt2"> PULSE</div>
          </div>
          <div className="logHeadingTxt3 font-face-OpenSans">Technologies</div>
        </div>
        <div className="searchContainer">
          <input
            type="text"
            placeholder="Search..."
            className="inpTxt font-face-OpenSans"
            value={searchText}
            onChange={handleSearchChange}
          />
          {/* <button className="searchBtn" onClick={handleSearch}>🔍</button> */}
        </div>
      </div>
      <div className="col-5 d-flex justify-content-left align-items-center font-face-OpenSans">
        <b>
          <Link className="link-styles" to="/">HOME</Link>
          <span className="menuSeperator mx-2">|</span>
          <Link className="link-styles" to="design">DESIGN SERVICES</Link>
          <span className="menuSeperator mx-2">|</span>
          <Link className="link-styles" to="rpo">RPO</Link>
          <span className="menuSeperator mx-2">|</span>
          <Link className="link-styles" to="jobs">JOBS</Link>
          <span className="menuSeperator mx-2">|</span>
          <Link className="link-styles" to="contact">CONTACT US</Link>
        </b>
      </div>
    </div>
  );
}

export default Header;

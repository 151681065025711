




import "./quntam_plus.css";
function QuantumPlus() {
  return (
    <div className="quantum-container">
      <img
        className="quantum-image"
        alt="QuantumPlusImg"
        src="./images/home/quntam_plus.png"
      />
      
      <div className="overlay-content">
        <div className="header-content">
          <div className="why-choose">
            <p>WHY</p>
            <p>CHOOSE</p>
          </div>
          <span className="question-mark">?</span>
        </div>

        <div className="title-content">
          <h2 className="quantum-title">QUANTUM PULSE</h2>
          <div className="technologies">TECHNOLOGIES</div>
        </div>
        
        <div className="description">
          Our Permanent Placement service at Quantum Pulse Technologies is designed to help businesses build a strong core team for long-term success. We manage the entire recruitment process, from sourcing and screening to interviewing and negotiating employment offers...
        </div>
      </div>
    </div>
  );
}

export default QuantumPlus;
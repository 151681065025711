import "./job_page.css";
import { useState } from "react";

function JobDetails() {
  const [currentPage, setCurrentPage] = useState(1);
  const [expandedIndex, setExpandedIndex] = useState(null);
  const jobsPerPage = 4;

  const jobList = [
    {
      title: "Software Engineer – Backend Developer",
      experience: "4 - 8 Years",
      location: "Chennai",
      qualification:
        "B.E/B.Tech, M.Sc./MCA in Computer Science or related fields.",
      description:
        "Ideal for professionals with experience in backend development, innovation in cloud computing, and virtualization.",
    },
    {
      title: "Senior Software Engineer",
      experience: "8 - 12 Years",
      location: "Hyderabad",
      qualification: "B.E/B.Tech in Electronics",
      description:
        "Ideal for framework developers skilled in C/C++, Python, OpenCLC++, and TVM.",
    },
    {
      title: "Design Engineer – RTL",
      experience: "8 - 15 Years",
      location: "Hyderabad",
      qualification: "B.E/B.Tech/M.E/M.Tech in CS or Electronics",
      description:
        "Expertise in RTL Design, Micro-architecture, ASIC, Verilog, and System Verilog.",
    },
    {
      title: "Accounts Manager – Chartered Accountant",
      experience: "8 - 15 Years",
      location: "Hyderabad",
      qualification:
        "Qualified/Semi-qualified CA, MBA (partially considerable)",
      description:
        "Experience in US, UK, and India Gaap, ERP systems like Netsuite, SAP, and taxation.",
    },
    {
      title: "Assistant Manager – SAP B1 Module",
      experience: "8 - 10 Years",
      location: "Chennai",
      qualification: "Bachelor’s in Computer Science or Information Systems",
      description: "Expert in SAP B1, Crystal Reports, PLD, and SQL Queries.",
    },
    {
      title: "Mechanical Engineer",
      experience: "3 - 8 Years",
      location: "Chennai",
      description:
        "Expertise in mechanical press technology, maintenance, and SPM machines.",
    },
    {
      title: "Business Development Manager",
      experience: "2+ Years",
      location: "Chennai",
      description:
        "HRIS/HRMS and software sales, with CRM and B2B sales knowledge.",
    },
    {
      title: "Business Development Executive",
      experience: "2 - 4 Years",
      location: "Bangalore",
      description:
        "Experience in Forex Trading sales, FX industry, and client handling.",
    },
    {
      title: "Human Resources – Generalist",
      experience: "2+ Years",
      location: "Chennai",
      qualification: "B.Com/MBA",
      description:
        "Expertise in recruitment, payroll, statutory compliance, and policy implementation.",
    },
    {
      title: "Hardware Design Engineer",
      experience: "3 - 5 Years",
      location: "Chennai",
      qualification:
        "Bachelor’s/Master’s in Electronics or Electrical Engineering",
      description:
        "Expert in analog/digital circuit design, power electronics, and embedded systems.",
    },
    {
      title: "DOT Net Developer",
      description:
        "Design and develop software programs in .Net technologies; handle database operations; build SQL Server artifacts such as stored procedures, views, functions, and tables. Collaborate with other programmers and ensure timely completion of tasks using Scrum methodologies.",
    },
  ];

  const totalPages = Math.ceil(jobList.length / jobsPerPage);

  const handleNext = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const handlePrevious = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const toggleAccordion = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  const currentJobs = jobList.slice(
    (currentPage - 1) * jobsPerPage,
    currentPage * jobsPerPage
  );

  return (
    <div className="jobPageBg p-4">
      <h1 className="jobPageHedTxt">Quantum Jobs</h1>
      <p className="jobPageDescTxt">
        Explore diverse career opportunities in cutting-edge industries.
      </p>

      {/* {currentJobs.map((job, index) => (
        <div key={index} className="jobDetailBg p-3 mb-3">
          <div className="d-flex justify-content-between align-items-center">
            <h3 className="jobHeadTxt">{job.title}</h3>
            <button className="infoButton" onClick={() => toggleAccordion(index)}>
              {expandedIndex === index ? "Hide Info" : "Info"}
            </button>
          </div>
          {expandedIndex === index && (
            <div className="jobDescTxt mt-2">
              <p><strong>Location:</strong> {job.location}</p>
              <p><strong>Experience:</strong> {job.experience}</p>
              <p><strong>Qualification:</strong> {job.qualification || "Not specified"}</p>
              <p><strong>Description:</strong> {job.description}</p>
            </div>
          )}
        </div>
      ))} */}
      {currentJobs.map((job, index) => (
        <div key={index} className="jobDetailBg p-3 mb-3">
          <div className="d-flex justify-content-between align-items-center">
            <h3 className="jobHeadTxt">{job.title}</h3>
            <button
              className="infoButton"
              onClick={() => toggleAccordion(index)}
            >
              {/* {expandedIndex === index ? "Hide Info" : "Info"} */}
              {/* ℹ️ */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <circle cx="12" cy="12" r="10" />
                <line x1="12" y1="16" x2="12" y2="12" />
                <line x1="12" y1="8" x2="12.01" y2="8" />
              </svg>
            </button>
          </div>
          {expandedIndex === index && (
            <div className="jobDescTxt mt-2">
              {job.location && (
                <p>
                  <strong>Location:</strong> {job.location}
                </p>
              )}
              {job.experience && (
                <p>
                  <strong>Experience:</strong> {job.experience}
                </p>
              )}
              {job.qualification && (
                <p>
                  <strong>Qualification:</strong> {job.qualification}
                </p>
              )}
              <p>
                <strong>Description:</strong> {job.description}
              </p>
            </div>
          )}
        </div>
      ))}

      <div className="d-flex justify-content-between">
        <button
          className="jobPreBtnSpan"
          onClick={handlePrevious}
          disabled={currentPage === 1}
        >
          PREVIOUS
        </button>
        <button
          className="jobNextBtnSpan"
          onClick={handleNext}
          disabled={currentPage === totalPages}
        >
          NEXT
        </button>
      </div>
    </div>
  );
}

export default JobDetails;
